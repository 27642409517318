* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  border: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app {
  display: flex;
  align-items: center;
}

.sidebar {
  width: 350px;
  background: #fff;
  height: 100vh;
  border-right: 1px solid #ddd;
  text-align: center;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 100;
}

.amount-input {
  font-size: 48px;
  font-weight: 800;
  border: 1px solid rgb(180, 180, 180);
  outline: none;
  width: 100%;
  border-radius: 10px;
  padding: 24px;
  margin-top: 20px;
  text-align: center;
}

.amount-input:focus {
  border: 1px solid rgb(138, 138, 138);
}

.button {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  padding: 12px;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 40px;
}

.amount-slider {
  width: 100%;
  margin-top: 20px;
}

.puzzle {
  background: url(/public/img/bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  width: 90vw;
  flex: 1;
  position: relative;
  left: -10px;
  bottom: 20px;
}

.puzzle-row {
  display: flex;
  position: relative;
  left: 10px;
}

.puzzle-row:nth-child(even) {
  margin-right: -75px;
}

.piece {
  display: block;
  background: #bd5858;
  width: 150px;
  height: 75px;
  box-shadow: 0 0 1px 1px #7d3232 inset;
  transition-duration: 300ms;
}

.sidebar h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  opacity: 0.6;
}

.sidebar p {
  font-weight: 800;
  font-size: 42px;
  margin-bottom: 50px;
  margin-top: 5px;
}

.sidebar p.green {
  color: #15803d;
}

.sidebar p.red {
  color: #dc2626;
}

.piece.big {
  width: 50px;
  height: 25px;
  margin-right: 10px;
  box-shadow: 0 0 3px 2px #7d3232 inset;
}

.piece-wrapper {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.last-row {
  justify-content: center;
  margin-left: 0px !important;
}

.setting-btn {
  display: block;
  padding: 3px 7px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 100;
}

.setting-btn:hover {
  background-color: #ccc;
}
